const About = () => {
    return (  
        <div className="home">
        <div className="flex flex-col justify-center text-left w-full">
            <div className="flex flex-col justify-center w-full items-center">
            <div className="avatar">
              <div className="w-32 mask mask-hexagon-2 my-10">
                <img src="https://uploads-ssl.webflow.com/63a633246709525a35661716/63b213a16c21e68d6410dcd3_7_2_sm-p-800.jpeg" />
              </div>
            </div>
            </div>

            <p>
Hi, I'm Daniel, and the founder of this service.
I've been programming since I was 12 years old. I studied and completed a M.Sc. in Computer Science at LMU Munich, published 3 scientific papers in AI and Quantum Computing, have done Machine Learning professionally for multiple years, and product development professionally for more than 6 years. I ran Aqarios for 1.5 years as a managing director and scaled it from 0 to 24 people.
            </p>
            <br/>
            <p>
Currently, I am CTO and Co-Founder at <a className="link" href="https://rhome.world/">rhome</a>, where we enable international work. Check it out if you are interested in enabling mobility for your workforce!
            </p>
            <br/>
            <p>
If you'd like to get to know me a bit better, let's connect on <a className="link" href="https://www.linkedin.com/in/daniel-ratke/">LinkedIn</a>. If you like, you can also visit my <a className="link" href="https://blog.xa0.de/list">personal blog</a>.
            </p>
            <p>
            <br/><b>ABOUT FLAMINGOO</b><br/>
Leaking prompts that your company or you have engineered is often not desired, since it may contain months of iteration and is essentially IP of the company. Flamingoo comes in as a firewall of sorts between user input from your application, and your call to the OpenAI API, thus securing you against these types of attacks. As of yet the model outputs a simple yes/no prediction as to whether a prompt is secure or not. There are mainly two aspects checked: Firstly, the prompt is checked against a library of well-known attacks and user prompts that leak your prompt. Secondly, a classification model is employed that checks whether your prompt could be malicious. This model has been trained on a medium-size dataset, and is still being improved.
            </p>
        </div>
        </div>
    );
}

export default About;
