import logo from './logo.png'
import logo_long from './logo_long.png'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IP } from './config.js';

import { useAuth } from "./Auth";

const Navbar = () => {
   const { user, setUser } = useAuth();

  useEffect(() => {
      // console.log("###########");
      // console.log(user);
  }, [user]);

  return (
        <div className="navbar shadow-2xl bg-base-200 roundedborders">
          <div className="flex-1">
            <Link to="/home">
              <img src={ logo_long } className="App-logo" alt="logo" />
            </Link>
          </div>
          {user && 'id' in user ?
            <div className="flex-none">
              <ul className="menu menu-horizontal px-1">
              <li className="mx-2"><Link to="/home">Home</Link></li>
              <li className="mx-2"><Link to="/settings">Settings</Link></li>
              <li className="mx-2"><Link to="/apikey">API Key</Link></li>
              <li className="mx-2"><Link to="/docs">Docs</Link></li>
              <li className="mx-2"><Link to="/pricing">Pricing</Link></li>
              <li className="mx-2"><Link to="/logout">Logout</Link></li>
              </ul>
            </div>
          :
          <div className='flex-none'>
              <ul className="menu menu-horizontal px-1">
            <li className="mx-2"><Link to="/login">Login</Link></li>
            <li className="mx-2"><Link to="/register">Register</Link></li>
              </ul>
            </div>
          }
        </div>
    );
}
 
export default Navbar;
