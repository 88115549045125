import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect, createContext, useContext, useState } from "react";
import { IP } from './config.js';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    fetch(IP + '/home', {
      method: 'GET',
      mode: "cors",
      credentials: "include",
    }).then((resp) => {
      if (resp.ok) {
        return resp.json()
      }
      setUser(null);
    }).then((json) => {
        // console.log("LOG", json);
        if ("error" in json) {
            setUser(null);
            return {};
        }
        setUser(json);
    }).catch((error) => {
        // console.log("something went wrong", error);
        setUser(null);
    });
  }, []);

  // const [user, setUser] = useState(() => {
  //   return fetch(IP + '/home', {
  //     method: 'GET',
  //     mode: "cors",
  //     credentials: "include",
  //   }).then((resp) => {
  //     if (resp.ok) {
  //       return resp.json()
  //     }
  //     return null;
  //   }).then((json) => {
  //       console.log("LOG", json);
  //       if ("error" in json) {
  //           return {};
  //       }
  //       return json;
  //   }).catch((error) => {
  //       console.log("something went wrong", error);
  //       return null;
  //   });
  // });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const RequireAuth = () => {
  const { user } = useAuth();
  const location = useLocation();

  if (user === undefined) return null;

  return user !== null ? <Outlet /> : <Navigate to="/login" />;
};
