import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from './config.js';

const Forgot = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {email};
        // console.log(user)

        fetch(IP + '/forgot', {
            method: 'POST',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'application/json',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(user)
            }).then(res => {
                // console.log(res.status);
                res.json().then(data => {
                    // console.log(data)
            })
            if (res.status === 202) {
                alert('Password has been reset')
                navigate('/home')
            } else if (res.status === 404) { 
                alert('User not found')
            }
        })
    }
        
    return ( 
        <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-200 mb-10">
            <div className="card-body">
                <p>Enter your email address and a mail will be sent to you to reset your password.</p>
                <form onSubmit={ handleSubmit }>
                    <div className="form-control">
                        <label className="label">
                        <span className="label-text">Email</span>
                        </label>
                        <input placeholder="email" className="input input-bordered w-full max-w-xs"
                            type="email"
                            required
                            value={ email }
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-control mt-6">
                        <button className="btn btn-primary">Submit</button>
                    </div>
                </form>
            </div>
        </div>
     )
}
 
export default Forgot;
