const Jobs = () => {
    return (  
        <div className="home">
        <div className="flex flex-col justify-center text-left w-full">
            <p>
        I am always looking for technical people in the following stack / domain:
            </p>

<ul className="steps">
  <li data-content="+" className="step step-neutral">Python</li>
  <li data-content="+" className="step step-neutral">ReactJS</li>
  <li data-content="+" className="step step-neutral">VueJS</li>
  <li data-content="+" className="step step-neutral">Tailwind</li>
  <li data-content="+" className="step step-neutral">PostgreSQL / MongoDB</li>
  <li data-content="+" className="step step-neutral">C++</li>
</ul>

            <p>
        Knowledge in Machine Learning or NLP is a plus. If you are interested in Quantum Computing, I can also help out with projects or industry contacts. If you'd like to cofound something or you are looking for funding, I can help out as well.
            </p>
        <br/>
            <p>
        Simply contact me at info@overpassage.com.
            </p>

        </div>
        </div>
    );
}

export default Jobs;
