import { useState, useEffect } from "react";
import { IP } from './config.js';
import { loadStripe } from "@stripe/stripe-js";


const Home = () => {
    let [text, setText] = useState('');
    let [addtext, setAddText] = useState('');
    let [chance, setChance] = useState('');
    const [api_key, setApi_key] = useState(() => {
            fetch(IP + '/home', {
                method: 'GET',
                headers: { "Authorization" : 'Basic',
                           "Content-Type" : 'text/html',
                           "Access-Control-Allow-Origin": 'true'},
                mode: 'cors',
                credentials: 'include',
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            // console.log(data);
            setApi_key(data["key"])
            setTrialType(data["trial_type"])
        });
    });
    const [stripe, setStripe] = useState();
    const [trialType, setTrialType] = useState(false);
    const [showCopyAlert, setShowCopyAlert] = useState(false);
      
    const handleCheck = (e) => {
        e.preventDefault();
        const uploadtext = { key: api_key, text: text }; 
        fetch(IP + '/check', {
            method: 'POST',
            headers: { "Authorization" : 'Basic',
                        "Content-Type" : 'application/json',
                        "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(uploadtext)
        }).then(res => {
            return res.json();
        }).then(data => {
          if ("msg" in data) {
            alert(data["msg"]);
          } else {
            setChance(data.secure.toString());
          }
        })
    }

    const createCheckoutSession = () => {
      return fetch(IP + "/init_checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        // body: JSON.stringify({
        //   "amount": amount,
        // })
      }).then(function(result) {
        return result.json();
      });
    }

    const handleEnable = (e) => {
        e.preventDefault();
        createCheckoutSession().then(function(data) {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId
            });
        });
    }

    useEffect(() => {
      const stripePromise = loadStripe(
        "pk_live_51LH983ESQyOGrzeLgTW9gk6KSCWbxpa1DwtTLiMpWEv8jEy1xwqy1Y3peM9ee3wRxSFPAID3z2EQ8zLfV1kU0epa00F2F9Rk39"
      );

      stripePromise.then((ret) => {
        setStripe(ret);
      });

      function handleKeyDown(e) {
        if (e.keyCode === 70) {
          // console.log(api_key);
          navigator.clipboard.writeText(api_key);
          setShowCopyAlert(true);
          setTimeout(() => setShowCopyAlert(false), 3000);
        }
      }

      document.addEventListener('keydown', handleKeyDown);

      // Don't forget to clean up
      return function cleanup() {
        document.removeEventListener('keydown', handleKeyDown);
      }
    }, []);

    return (
    <div className="home">
    Below you are able to test the API without code.
    <div className="myform">
      <form className="flex flex-col items-center" onSubmit={ handleCheck }>
          {/* <label>Input your text</label> */}
          <textarea className="textarea textarea-bordered textarea-md w-full" placeholder="Input your text..."
              required
              value={ text }
              onChange={(e) => setText(e.target.value)}
          ></textarea>
          <button className="btn btn-primary btn-square my-5">
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
          </button>
      </form>
    </div>
{ chance ?
        <div className="chance">Secure: {chance}</div>
: ""}
        <p> Leaking prompts that your company or you have engineered is often not desired, since it may contain months of iteration and is essentially IP of the company. As of yet the model outputs a simple yes/no prediction as to whether a prompt is secure or not. There are mainly two aspects checked: Firstly, the prompt is checked against a library of well-known attacks and user prompts that leak your prompt. Secondly, a classification model is employed that checks whether your prompt could be malicious. This model has been trained on a medium-size dataset, and is still being improved.
        </p>
    </div>
    ); 
}
 
export default Home;
