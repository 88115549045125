import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from './config.js';
import { loadStripe } from "@stripe/stripe-js";

const Settings = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {password, password1};

        fetch(IP + '/settings', {
            method: 'POST',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'application/json',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(user)
            }).then(res => {
              res.json().then(data => {
                alert('Password updated!')
              })
            });
    }

    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const [stripe, setStripe] = useState();
    const [trialType, setTrialType] = useState("");

    const [api_key, setApi_key] = useState(() => {
        fetch(IP + '/home', {
            method: 'GET',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'text/html',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            setApi_key(data["key"])
            setTrialType(data["trial_type"])
        });
    });

    const handleEnable = (e) => {
        e.preventDefault();
        createCheckoutSession().then(function(data) {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId
            });
        });
    }

    const createCheckoutSession = () => {
      return fetch(IP + "/init_checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
      }).then(function(result) {
        return result.json();
      });
    }

    useEffect(() => {
      const stripePromise = loadStripe(
        "pk_live_51LH983ESQyOGrzeLgTW9gk6KSCWbxpa1DwtTLiMpWEv8jEy1xwqy1Y3peM9ee3wRxSFPAID3z2EQ8zLfV1kU0epa00F2F9Rk39"
      );

      stripePromise.then((ret) => {
        setStripe(ret);
      });

    }, []);

    return ( 
        <div className="settings" style={{width: "800px"}}>
        <section className="">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white-900">Pricing</h2>
                  <p className="mb-5 font-light text-white-900 sm:text-xl dark:text-white-900">Secure your AI intellectual property.</p>
              </div>
              <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                      <h3 className="mb-4 text-2xl font-semibold">Trial</h3>
                      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Best option for personal use & for your next project.</p>
                      <div className="flex justify-center items-baseline my-8">
                          <span className="mr-2 text-4xl font-extrabold">0€</span>
                          <span className="text-gray-500 dark:text-gray-400">/month</span>
                      </div>
                      <ul role="list" className="mb-8 space-y-4 text-left">
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>20 requests / mo</span>
                          </li>
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>Unlimited users</span>
                          </li>
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>Free updates</span>
                          </li>
                      </ul>
                      <a href="#" className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Free</a>
                  </div>
                  <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                      <h3 className="mb-4 text-2xl font-semibold">Company</h3>
                      <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Relevant for small businesses and startups.</p>
                      <div className="flex justify-center items-baseline my-8">
                          <span className="mr-2 text-4xl font-extrabold">9€</span>
                          <span className="text-gray-500 dark:text-gray-400">/month</span>
                      </div>
                      <ul role="list" className="mb-8 space-y-4 text-left">
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>400 requests / mo</span>
                          </li>
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>No setup, or hidden fees</span>
                          </li>
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>Unlimited users</span>
                          </li>
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>Premium support</span>
                          </li>
                          <li className="flex items-center space-x-3">
                              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                              <span>Free updates</span>
                          </li>
                      </ul>
                      { trialType == 'trial' ?
                          <>
                              <button className="btn btn-primary" onClick={(e) => handleEnable(e)}>Get that key</button>
                          </>
                      :
                          <>
                              <button className="btn btn-primary btn-disabled text-black dark:text-white-900" onClick={(e) => handleEnable(e)}>Bought</button>
                          </>
                      }
                  </div>
              </div>
              <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 mt-10">
                  <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white-900">Need more requests?</h2>
                  <p className="mb-5 font-light text-white-900 sm:text-xl dark:text-white-900">We have flexible flat pricing per request as well: 2c / request.</p>
                  <p className="mb-5 font-light text-white-900 sm:text-xl dark:text-white-900">We also have flexible subscriptions. Want exactly 5000 requests / month? We can do that for 100€ per month.</p>
              </div>
          </div>
        </section>


        </div>
     );
}
 
export default Settings;
