import Navbar from './Navbar';
import Footer from './Footer';
import Register from './Register';
import Home from './Home';
import Login from './Login';
import Logout from  './Logout';
import Docs from './Docs';
import Settings from './Settings';
import APIKey from './APIKey';
import Forgot from './Forgot';
import About from './About';
import Contact from './Contact';
import Jobs from './Jobs';
import Privacy from './Privacy';
import Pricing from './Pricing';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { IP } from './config.js';

import { AuthProvider, RequireAuth } from "./Auth";

function App() {
  return (
        <AuthProvider>
          <BrowserRouter>
            <div className="App">
              <Navbar />
              <div className="content flex justify-center">
                <Routes>
                  <Route element={<RequireAuth />}>
                    <Route path='/home'  element={<Home />} />
                    <Route path='/docs'  element={<Docs />} />
                    <Route path='/settings'  element={<Settings />} />
                    <Route path='/apikey'  element={<APIKey />} />
                  </Route>

                  <Route path='/login'  element={<Login />} />
                  <Route path='/register'  element={<Register />} />
                  <Route path='/logout'  element={<Logout />} />
                  <Route path='/forgot'  element={<Forgot />} />

                  <Route path='/pricing'  element={<Pricing />} />

                  <Route path='/about' element={<About />} />
                  <Route path='/contact' element={<Contact />} />
                  <Route path='/imprint' element={<Contact />} />
                  <Route path='/jobs' element={<Jobs />} />
                  <Route path='/privacy' element={<Privacy />} />

                  <Route path="*" element={<Login />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </BrowserRouter>
        </AuthProvider>
  );
}

export default App;
