import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from './config.js';
import { loadStripe } from "@stripe/stripe-js";

const Settings = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {password, password1};

        fetch(IP + '/settings', {
            method: 'POST',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'application/json',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(user)
            }).then(res => {
              // console.log(res.status);
              res.json().then(data => {
                // console.log(data)
                alert('Password updated!')
              })
            });
    }

    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const [stripe, setStripe] = useState();
    const [trialType, setTrialType] = useState(true);

    const [api_key, setApi_key] = useState(() => {
        fetch(IP + '/home', {
            method: 'GET',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'text/html',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            setApi_key(data["key"])
            setTrialType(data["trial_type"])
        });
    });

    const handleEnable = (e) => {
        e.preventDefault();
        
        createCheckoutSession().then(function(data) {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId
            });
        });
    }

    const createCheckoutSession = () => {
      return fetch(IP + "/init_checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
      }).then(function(result) {
        return result.json();
      });
    }

    function copyAPIKey() {
      navigator.clipboard.writeText(api_key);
      setShowCopyAlert(true);
      setTimeout(() => setShowCopyAlert(false), 3000);
    }

    useEffect(() => {
      const stripePromise = loadStripe(
        "pk_test_51LH983ESQyOGrzeLB7NeBxTxp4qQan19jMjIY6I67p2EdiBruGWF7qvB2Ci4cS1Eb8SlKQ1zGiaJdoTyuPI3vD5N00JsUFmiyB"
      );

      stripePromise.then((ret) => {
        setStripe(ret);
      });

      function handleKeyDown(e) {
        if (e.keyCode === 70) {
          copyAPIKey();
        }
      }

      document.addEventListener('keydown', handleKeyDown);

      return function cleanup() {
        document.removeEventListener('keydown', handleKeyDown);
      }
    }, [api_key]);

    return ( 
        <div className="settings" style={{width: "800px"}}>
        <br />
        <h2>Your API key is:</h2>

        <div className="form-control my-5">
          <div className="input-group">
            <input type="text" placeholder="" className="input input-bordered w-full" value={api_key} />
            <button className="btn btn-square" onClick={() => copyAPIKey()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
            </button>
          </div>
        </div>

        { showCopyAlert ?
            <div className="alert alert-success shadow-lg fixed" style={{width: "130px", top: "50px", right: "100px"}}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="strokeCurrent flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>Copied!</span>
            </div>
            </div>
        : ""}
            Press <kbd className="kbd kbd-sm">F</kbd> to pay respects (and copy the key)<br/>
        { trialType === 'trial' ?
            <>
                <p></p>
                <p>Need more requests?</p>
                <button className="btn btn-primary" onClick={(e) => handleEnable(e)}>Upgrade key</button>
            </>
        : ""}
        <br /><br />
        </div>
     );
}
 
export default Settings;
